<template>
  <div id="general">
    <section class="site-section">
      <div class="container">
        <div class="section-header">
          <h2 class="section-title" v-text="content.titleHEB" />
        </div>

        <div class="section-body">
          <div class="row">
            <div class="col-md-10">
              <Loading v-if="loading" />

              <div v-else class="general-text" v-html="content.textHEB" />
            </div>

            <div class="col-md-2" v-if="content.menuSide">
              <div class="general-links">
                <nav class="nav flex-column" >
                  <li class="nav-item" v-for="(item, index) of content.menuSide" :key="index">
                    <a v-if="item.target" :href="item.url" target="_blank" v-text="item.titleHEB" />
                    <router-link v-else :to="{name: 'content', params: { contentId: item.url }}" class="nav-link" active-class="active">
                      {{ item.titleHEB }}
                    </router-link>
                  </li>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'General',

  metaInfo() {
    return {
      title: this.seoContent.title !== '' ? this.seoContent.title : process.env.VUE_APP_NAME+' | '+this.content.titleHEB+' | '+process.env.VUE_APP_NAME_HEB,
      meta: [
        { name: 'keywords', content: this.seoContent.keywords },
        { name: 'description', content: this.seoContent.description }
      ],
    }
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    seoContent() {
      return this.$store.getters.seoContent
    },
    content() {
      return this.$store.getters.content
    },
  },

  watch: {
    async $route() {
      await this.loadContent()
    },
  },

  async created() {
    await this.loadContent()
  },

  methods: {
    async loadContent() {
      this.loading = true
      await this.$store.dispatch('getContent', this.$route.params.contentId)
      window.scrollTo(0, 0)
      this.loading = false
    },
  },

  destroyed() {
    this.$store.commit('setContent', {})
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

.general-text, .general-links{
  text-align: right;
  direction: rtl;
}
.general-links a{
  color: #8D8D8D;
  font-size: .8rem;
  padding: 0;
  margin-bottom: .6rem;
}
.general-links a:hover{
  text-decoration: underline;
}
.section-header{
  margin-bottom: 2rem;
}
.section-title{
  direction: rtl;
}

@include media-breakpoint-down(sm) {
  .site-section{
    padding-top: 1rem;
  }
  .row {
    flex-direction: column-reverse;
  }
  .general-links{
    margin-bottom: 1rem;
  }
}
</style>
